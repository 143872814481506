import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import {
    Container,
    Row,
    Col,
    Card
} from 'reactstrap';
import styles from './styles.module.scss';
import Link from "next/link"

export default function AllSectors({
    global,
    pathPrefix,
    customClass = "",
    content,
    post,
    locale = "",
    sectionId
}) {
    return (
        <section className={`${styles.root} ${customClass} py-1`} id={`${sectionId}`}>
            <Container>
                {content ?
                    <div className="mb-5 text-center">
                        <SourceFlowText global={global} path={`${pathPrefix}.content`} type="html">
                            {content}
                        </SourceFlowText>
                    </div> : null}
                <Row className="wrapper flex-wrap justify-content-center text-center">
                    {post?.map((post, index) => (
                        post?.title == "Quality Assurance & Testing" ||
                            post?.title == "UI & UX" ||
                            post?.title == "Project/Program Management, Change Managment & Business Analysis" ||
                            post?.title == "Artificial Intelligence" ||
                            post?.title == "Change and Transformation" ||
                            post?.title == "Business Analysis" ||
                            post?.title == "Networking and Infrastructure" ||
                            post?.title == "Testing" ||
                            post?.title == "ERP & Customer Relationship  Management" ||
                            post?.title == "Business Analysis, Change and Transformation"
                            ?
                            ""
                            :
                            <Card key={index} className="bg-transparent border-0 mb-4">
                                <div className="mb-4 overflow-hidden rounded-4">
                                    <Link href={`${locale}/sectors/${post.url_slug}/`}>
                                        <a>
                                            <SourceFlowImage
                                                src={post.image ? post.image : "/site-assets/placeholders/img-placeholder.jpg"}
                                                size="800x800"
                                                alt={post?.title}
                                                className="card-img"
                                            />
                                        </a>
                                    </Link>
                                </div>
                                <Link href={`${locale}/sectors/${post.url_slug}/`}>
                                    <a>
                                        <p className="fs-5"><strong>{post?.title}</strong></p>
                                    </a>
                                </Link>

                            </Card>
                    ))}
                </Row>
            </Container>
        </section>
    )
}