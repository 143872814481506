import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import {
    Container,
    Row,
    Col,
    NavItem,
    Nav,
    Card
} from 'reactstrap';
import Link from "next/link"
import styles from './styles.module.scss';
import Arrow from "/public/site-assets/svg/black-arrow.svg"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper"

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

export default function LatestNews({
    global,
    pathPrefix,
    customClass = "",
    content,
    post,
    buttonLink,
    buttonTitle,
    swiperID,
    locale
}) {
    const settings = {
        modules: [Navigation, Pagination, Autoplay],
        loop: false,
        spaceBetween: 30,
        slidesPerView: 1,
        speed: 500,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            clickable: true,
            el: `.swiper-custom-paginations .${swiperID}`,
        },
        navigation: {
            nextEl: `.${swiperID}.swiper-next`,
            prevEl: `.${swiperID}.swiper-prev`
        },
        breakpoints: {
            1200: {
                slidesPerView: 4,
            },
            991: {
                slidesPerView: 3,
            },
            767: {
                slidesPerView: 2,
            }
        }
    }
    return (
        post?.length >= 1 && (
            <section className={`${styles.root} ${customClass}`} >
                <Container>
                    {content ?
                        <div className="text-center">
                            <SourceFlowText global={global} path={`${pathPrefix}.content`} type="html">
                                {content}
                            </SourceFlowText>
                        </div>
                        : null}
                    {buttonLink ?
                        <div className="text-center mt-4">
                            <Link href={`${buttonLink}`}>
                                <a className="primaryBtn no-arrow m-0">
                                    {buttonTitle}
                                </a>
                            </Link>
                        </div> : null}
                    <div className="pt-5 position-relative">
                        <div className="d-flex position-absolute swiperArrowWrapper">
                            <button className={`${swiperID} arrow swiper-prev border-0 p-0`} aria-label="Prev"><Arrow /></button>
                            <button className={`${swiperID} arrow swiper-next border-0 ms-2 p-0`} aria-label="Next"><Arrow /></button>
                        </div>
                        <Swiper {...settings}>
                            {post?.map((post, index) => (
                                <SwiperSlide key={index}>
                                    <Card className="overflow-hidden h-100">
                                        <Link href={`/${locale ? locale : ""}blog/${post.url_slug}/`}>
                                            <a className="d-flex flex-wrap justify-content-between h-100">
                                                <div className="position-relative">
                                                    <SourceFlowImage
                                                        src={post?.image ? post.image : "/site-assets/placeholders/img-placeholder.jpg"}
                                                        size="800x500"
                                                        alt={post?.title}
                                                        className="card-img rounded-0"
                                                        width="100%"
                                                        height="100%"
                                                    />
                                                    {/* {post.tag?.en ?
                                                        <div>
                                                            <span className="tertiary-bg text-black d-inline-block px-2 rounded-3 position-absolute post-tag"><small><strong>  {post.tag?.map((post, index) => (post))}</strong></small></span>
                                                        </div> : null} */}
                                                    {post?.tag?.length >= 1 && (
                                                        <div className="position-absolute post-tag">
                                                            {post.tag?.map((post, index) => (
                                                                <span className="bg-white shadow text-black d-inline-block px-2 rounded-3 me-2 mb-2" key={index}>
                                                                    {post}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="conteWrapper p-3">
                                                    <div>
                                                        <p className="post-title"><strong>{post?.title}</strong></p>
                                                        {post?.short_description ?
                                                            <p className="post-description">{post.short_description}</p>
                                                            : ""}
                                                        {post?.salary_package ?
                                                            <p className="post-salary">
                                                                {post?.salary_package}
                                                            </p>
                                                            : ""}
                                                    </div>
                                                    <span>
                                                        Read More
                                                    </span>
                                                </div>
                                            </a>
                                        </Link>
                                    </Card>
                                </SwiperSlide>
                            ))
                            }
                        </Swiper>
                    </div>
                    <div className="text-center swiper-custom-paginations mt-4">
                        <div className={`${swiperID}`} />
                    </div>
                </Container>
            </section>
        )
    )
}